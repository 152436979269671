<template>
  <b-modal
    id="modal-tutorial"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <b-container class="my-2">
      <b-row class="py-2">
        <b-col
          cols="12"
          class="text-center"
        >
          <b-img
            width="90"
            :src="require(`@/assets/images/icons/${item.image}.svg`)"
          />
          <h6 class="size16 text-dark fw-bold-800 mt-2">
            {{ item.text1 }}
          </h6>
          <small class="size14 text-dark text-darken-4 fw-bold-400">{{ item.text2 }}</small>
          <br>
          <b-button
            class="px-2 mt-2"
            @click="step == 1 ? nextStep() : startTutorial()"
            id="button--tutorial"
          >
            {{ item.buttonText }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BModal, VBToggle, BButton, BContainer, BImg,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      is_ritel: true,
      queue_name: '',
      queue_number: '',
      isLoading: false,
      step: 1,
      item: {
        image: 'group_receipt',
        text1: 'Yuk mulai tutorial untuk memulai transaksi',
        text2: 'Anda akan di bimbing mengenai penggunaan aplikasi toqoo ini',
        buttonText: 'Ok, mulai sekarang',
      },
    }
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  created() {
  },
  methods: {
    nextStep() {
      this.step = 2
      if (this.step === 2) {
        this.item = {
          image: 'group_lightning',
          text1: 'Mulai tanpa ribet, tidak perlu repot input ribuan barang di awal!',
          text2: 'Sebagian besar produk yang sering di transaksikan dan sesuai dengan bidang toko Anda sudah kami sediakan.',
          buttonText: 'Mulai tutorial transaksi',
        }
      }
    },
    startTutorial() {
      this.$bvModal.hide('modal-tutorial')
      this.$tours.cashierTour1.start()
    },
  },
}
</script>

<style lang="scss">
#modal-tutorial {
  .modal-dialog {
    // width: 580px;
    max-width: 580px;
    .modal-content {
      border-radius: 24px;
    }
  }
}
</style>
